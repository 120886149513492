import { useRailsContext } from 'components/rails-context'
import * as React from 'react'

export type RailsActionFormProps = {
  method: 'post' | 'delete'
  formMethod?: string
  action: string
  /**
   * Content to render inside the form. This should at least contain a button with type="submit"
   */
  children: React.ReactNode
}

export const RailsActionForm = (props: RailsActionFormProps) => {
  const { method, action, children, formMethod } = props

  const { csrfToken } = useRailsContext()

  return (
    <form className="flex gap-2" action={action} method={formMethod || method}>
      <input type="hidden" value={method} name="_method" />
      <input type="hidden" value={csrfToken} name="authenticity_token" />

      {children}
    </form>
  )
}
